

interface StringMapAny {
  [name: string]: any
}

/** nested is used to check for field within an object, each parameter going in a level. */
export function nested(...args: any[]): any {
  var obj = null;
  var nest = null;
  try {
    for (var i = 0; i < args.length; i++) {
      if (i == 0) {
        obj = args[i];
        nest = obj;
        if (typeof obj != "object") {
          return null;
        }
        continue;
      }
      var name = args[i];

      if (nest === null || nest === undefined) {
        return null;
      }
      
      nest = nest[name];
      if (typeof nest == "undefined") {
        return null;
      }    
    }
  } catch (e) {
    throw e;
  }
  return nest;
}


export function inop(...args: any[]) : boolean {
  var src: any;
  for (var i = 0; i < args.length; i++) {
    if (i == 0) {
      src = args[i];
      continue;
    }
    if (src == args[i]) {
      return true;
    }
    if (src != null && typeof src != "undefined" 
      && (src instanceof Object || src instanceof Array) 
      && 'length' in src) {
      for (var k = 0; k <= src.length; k++) {
        if (src[k] == args[i]) {
          return true;
        }
      }
    }
    
  }
  return false;
}


export function iinop(...args: any[]) : boolean {
  var src : any;
  for (var i = 0; i < args.length; i++) {
    if (i == 0) {
      src = args[i];
      continue;
    }
    if (src === args[i]) {
      return true;
    }
    if (typeof args[i] == 'string' && typeof src == 'string') {
      if (src.toLowerCase() == args[i].toLowerCase()) {
        return true;
      }
    }

    if (src != null && typeof src != "undefined" 
      && (src instanceof Object || src instanceof Array) 
      && 'length' in src) {
      for (var k = 0; k <= src.length; k++) {
        if (args[i] instanceof String && src[k] instanceof String) {
          if (src[k].toLowerCase() == args[i].toLowerCase()) {
            return true;
          }
        }
        if (src[k] == args[i]) {
          return true;
        }
      }
    }
    
  }
  return false;
}


export function replace_all(p_str : string,p_sub: string,p_replace: string) : string {
  var m_str = p_str;
  var cnt;
  var maxcnt = m_str.length;

  for (cnt = 0; cnt < maxcnt; cnt++) {
    if (m_str.includes(p_sub))
    {
      m_str = m_str.replace(p_sub,p_replace);
    }
    else {
      break;
    }
  }
  return m_str;
}


export function ifblnk(...args: any[]) : any {
  var prev = "";
  for (var i = 0; i < args.length; i++) {
    let isnum = !isNaN(args[i]);
    if (args[i] == null || args[i] == undefined 
      || args[i] == "" || isnum && args[i] == "0") {
      continue
    }
    return args[i];
  }
  return null;
}

export function buildURL(relurl : string, parms : StringMapAny) {
  var baseurl : string = "";
  if (!(relurl.indexOf('http') >= 0)) {
    baseurl =  window.location.href;
  }
  var urlObj = new URL(relurl, baseurl);
  for (let k in parms) {
    urlObj.searchParams.set(k, parms[k]);
  }

  return  urlObj.toString();
}


export function getISODateStr(date? : Date | String | Number) : String | null {
  if (typeof date == undefined || date == null) {
    date = new Date();
    return getLocalDateISOStr(date).substr(0,10);
  }
  if (typeof date == "object" && 'toISOString' in date) {
    return getLocalDateISOStr(date).substr(0,10);
  }
  if (typeof date == "string") {
    var newdate = new Date(Date.parse(date));
    return getLocalDateISOStr(newdate).substr(0,10);
  }
  return null;
}

export function ISOStrToDate(strdate? : String | null) : Date | undefined {
  
  if (typeof strdate == undefined || strdate == null) {
    return ;
  }
  if (typeof strdate == "string" && (strdate == "now" || strdate == "today")) {
    let date = new Date();
    return date;
  }
  else if (typeof strdate == "string") {
    let date = new Date(Date.parse(strdate));
    return date;
  }
  return;
}


export function getISOTimeStr(time : any,  detailed? : boolean) {
  var to = detailed ? 12 : 8;
  if (typeof time == undefined || time == null) {
    var date = new Date();    
    return getLocalDateISOStr(date).substr(11,to);
  }
  if (typeof time == "string") {
    var newdate = new Date(Date.parse(time));
    if (newdate instanceof Date ) {
      newdate = new Date(Date.parse("2000-01-01T" + time));
    }
    return getLocalDateISOStr(newdate).substr(11,to);
  }

}



export function getLocalDateISOStr(d : Date) : string {
  let pad = function(n : number) : string {
    return n < 10 ? '0'+String(n) : String(n);
  }
  return d.getFullYear()+'-'
            + pad(d.getMonth()+1)+'-'
            + pad(d.getDate())+'T'
            + pad(d.getHours())+':'
            + pad(d.getMinutes())+':'
            + pad(d.getSeconds())+'.'
            + pad(d.getMilliseconds())+'Z'
  ;
}


export function stringify(input : any, level : number) : string {
  if (!input) {
    return input;
  }

  level = level || 4;

  var objectsAlreadySerialized = [input],
      objDepth = [input];


  return JSON.stringify(input, function (key, value) {
      if (typeof value == "function") {
        return "function";
      }
      if (key) {
          if (typeof value === 'object') {
              if (objectsAlreadySerialized.indexOf(value) !== -1)
                  return undefined;

              objectsAlreadySerialized.push(value);
          }

          if (objDepth.indexOf(this) === -1)
              objDepth.push(this);
          else while(objDepth[objDepth.length-1] !== this)
              objDepth.pop();

          if (objDepth.length > level)
              return undefined;
      }

      return value;
  });
}



export function Debounce(fn : any, waitForSec? : number , immediate? : boolean) {
  var timeout : any;
  if (!waitForSec) {
    waitForSec = 500;
  }
  if (immediate == undefined) {
    immediate = false;
  }
  var bouncer: any = function () {
    let context : any = this;
    let result;
    let args = arguments;

    if (timeout) {
      clearTimeout(timeout);
    }

    if (immediate) {
      let callNow = !timeout;
      timeout = setTimeout(function () {
        timeout = null;
      }, waitForSec)
      if (callNow) {
        result = fn.apply(context, args);
      } 
    }
    else {
      timeout = setTimeout(function () {
        fn.apply(context, args)
      }, waitForSec);
    }
    return result;
  };

  bouncer.cancel = function () {
    clearTimeout(timeout);
    timeout = null;
  };

  return bouncer;
}
 

export function prettySize(pBytes : number) : string {
  let unit : Number = 0;
  if (pBytes > 1000000000) { 
    unit = Math.round(pBytes / 1000000000.0);
    return `${unit}GB`;
  } else if (pBytes > 1000000) { 
    unit = Math.round(pBytes / 1000000.0);
    return `${unit}MB`;
  } else if (pBytes > 1000) { 
    unit = Math.round(pBytes / 1000.0) ;
    return `${unit}KB`;
  }
  
  return `${unit}b`;
}



export function blobToBase64(pBlob : Blob, pRawBase64 : boolean | undefined) : Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      let res = reader.result;
      if (pRawBase64 && typeof res == "string") {
        let start = res.indexOf("base64,");
        res = res.substr(start >= 0 ? start + 7 : 0,res.length);
      }
      resolve(res);      
      
    };
    reader.readAsDataURL(pBlob);
  });
}


export function b64EncodeUnicode(str:any) : string {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode(parseInt(p1, 16))
  }))
}

export function b64DecodeUnicode(str:string) : any {
  return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}

export function waitTill(pTestCallback : any, pTrueCallback : any, pTimeoutCallback : any,pTimeOutSec : number) : void {  
  const testInterval = 30;
  let context : any = this;
  let timeoutMS = 60 * 1000;
  if (pTimeOutSec && pTimeOutSec > 0) {
    timeoutMS = pTimeOutSec * 1000;
  }
  let exists = false;
  if (typeof pTestCallback == "function" && typeof pTrueCallback == "function" ) {
    //todo, a safety to pickup duplicate calls.
    /*if (!window._waitTill_registry) {
      window._waitTill_registry = [];
    }
    window._waitTill_registry.forEach(function(r) {
      if (r.tester === pTestCallback) {
        exists = true;
      }
    });    
    window._waitTill_registry.push({"tester":pTestCallback, "callback": pTrueCallback});
    */
    let tested = pTestCallback.call(context);
    if (tested) {
      return pTrueCallback.call(context);
    }
    var startDate = new Date();
    var interval = setInterval(function() {
      let tested = pTestCallback.call(context);
      if (tested) {
        clearInterval(interval);
        return pTrueCallback.call(context);
      }
      let d = new Date();
      if (d.valueOf() - startDate.valueOf() > timeoutMS ) {

        clearInterval(interval);
        if (typeof pTimeoutCallback == "function") {
          pTimeoutCallback.call(context);
        }        
      }
    },testInterval);
  } else {
    throw 'Invalid callbacks given.';
  }
}

//For testing, will not really recommend default exports
export default {
  "nested": nested,
  "iinop": iinop,
  "inop": inop,
  "replace_all": replace_all,
  "stringify": stringify,
  "blobToBase64": blobToBase64,
  "prettySize": prettySize,
}
